import React from 'react';
import PropTypes from 'prop-types';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import FindConsultationTabPanel from './FindConsultationTabPanel';
import FindScansTabPanel from './FindScansTabPanel';
import { observer } from "mobx-react-lite"

const PageNavigationTabsStyled = styled('div')(({ theme }) => ({
    padding: '1rem',
    textTransform: 'none'
}));

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: 'none',
        '&.Mui-selected': {
            color: '#000',
            fontWeight: 'bold'
        },
        '&.Mui-focusVisible': {
            backgroundColor: '#19BFB7',
            fontWeight: 'bold'
        },
        '&:hover': {
            color: '#000',
            fontWeight: 'bold',
            opacity: 1,
        },
    }),
);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0 }}>
                    {children}
                </Box>
            )}
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};

const PageNavigationTabs = observer(props => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, val) => {
        setValue(val);
    };

    return (
        <PageNavigationTabsStyled>
            <Box sx={{ width: '100%' }}>
                <Box>
                    <Tabs value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        sx={{ borderBottom: 1, borderColor: '#C7E0DF' }}
                    >
                        <StyledTab label="Find a scan" {...a11yProps(1)} />
                        <StyledTab label="Find a consultation" {...a11yProps(0)} sx={{width: '17rem'}}/>
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <FindScansTabPanel></FindScansTabPanel>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <FindConsultationTabPanel ></FindConsultationTabPanel>
                    </TabPanel>
                </Box>
            </Box>
        </PageNavigationTabsStyled>
    );
});

export default PageNavigationTabs;